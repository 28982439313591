@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif; }

/* --- Import Fonts ---*/
@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap"); }

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap"); }

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap"); }

/* --- Global Variables ---*/
/* --- Global Mixins ---*/
/* --- Global Colors ---*/
.App {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .App {
      flex-wrap: nowrap; } }

@media (min-width: 768px) {
  .project-container {
    width: 60vw;
    position: static;
    overflow: visible;
    width: 60%;
    float: none; } }

/* 
* Changing navigation bullets colors 
*/
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: lightgrey; }

/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif; }

/* --- Import Fonts ---*/
@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap"); }

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap"); }

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap"); }

/* --- Global Variables ---*/
/* --- Global Mixins ---*/
/* --- Global Colors ---*/
.info-section {
  width: 100vw;
  height: 100vh; }
  @media (min-width: 768px) {
    .info-section {
      width: 40%; } }
  .info-section__container {
    width: 100%;
    height: 100vh;
    min-height: 400px;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .info-section__container {
        position: fixed;
        width: 40vw; } }
  .info-section__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%; }
  .info-section__title {
    font-size: 2.5rem;
    width: 200px; }
  .info-section__text {
    margin: 2em 0;
    line-height: 1.5rem;
    max-width: 350px; }
  .info-section__icon-wrapper {
    width: 175px;
    display: flex;
    justify-content: space-between; }
  .info-section__icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 2em; }
  .info-section__button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    width: 175px;
    margin-top: 3em;
    font-size: 0.8rem;
    background: lightpink;
    margin-top: 0; }
    @media (min-width: 768px) {
      .info-section__button {
        font-size: 1rem; } }

.bold {
  font-weight: bold; }

@charset "UTF-8";
/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif; }

/* --- Import Fonts ---*/
@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap"); }

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap"); }

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap"); }

/* --- Global Variables ---*/
/* --- Global Mixins ---*/
/* --- Global Colors ---*/
.project {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3em 0 3em 0; }
  @media (min-width: 768px) {
    .project {
      border-top: none; } }
  .project__container {
    box-sizing: content-box;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .project__image-container {
    display: flex;
    justify-content: center; }
  .project__image--desk {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--desk {
        max-width: 650px; } }
  .project__image--tablet {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--tablet {
        max-width: 650px; } }
  .project__text-container {
    width: 100%;
    margin-top: 5em; }
  .project__text {
    margin: 1em 0; }
  .project__title {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .project__title {
        font-size: 1.7rem; } }
  .project__type {
    text-transform: uppercase;
    color: #b3b2b0;
    font-size: 0.9rem;
    margin-top: 0.4em; }
  .project__tech {
    font-style: italic; }
  .project__demo-button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    width: 175px;
    margin-top: 3em;
    font-size: 0.8rem; }
    @media (min-width: 768px) {
      .project__demo-button {
        font-size: 1rem; } }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px 10px 10px 0;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 20px; }
    @media (min-width: 768px) {
      .Collapsible__contentInner p {
        font-size: 0.9rem; } }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
  color: grey;
  position: relative;
  border-bottom: 1px solid lightgrey;
  padding: 15px 10px 15px 0;
  color: black; }
  @media (min-width: 768px) {
    .Collapsible__trigger {
      font-size: 1rem; } }
  .Collapsible__trigger:after {
    font-family: "FontAwesome";
    content: "❮";
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    color: lightgrey; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.project--ts {
  border-top: 20px solid #b3cebf; }
  @media (min-width: 768px) {
    .project--ts {
      border-top: none;
      border-left: 20px solid #b3cebf; } }
  @media (min-width: 768px) {
    .project--ts__image-container {
      position: relative; } }
  .project--ts__desk:hover {
    -webkit-animation: move 1s linear;
            animation: move 1s linear; }
  .project--ts__tablet {
    display: none; }
    @media (min-width: 768px) {
      .project--ts__tablet {
        display: inline;
        display: initial;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 100px;
        -webkit-filter: none;
                filter: none; } }
  .project--ts__demo-button {
    background: #b3cebf; }

@charset "UTF-8";
/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif; }

/* --- Import Fonts ---*/
@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap"); }

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap"); }

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap"); }

/* --- Global Variables ---*/
/* --- Global Mixins ---*/
/* --- Global Colors ---*/
.project {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3em 0 3em 0; }
  @media (min-width: 768px) {
    .project {
      border-top: none; } }
  .project__container {
    box-sizing: content-box;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .project__image-container {
    display: flex;
    justify-content: center; }
  .project__image--desk {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--desk {
        max-width: 650px; } }
  .project__image--tablet {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--tablet {
        max-width: 650px; } }
  .project__text-container {
    width: 100%;
    margin-top: 5em; }
  .project__text {
    margin: 1em 0; }
  .project__title {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .project__title {
        font-size: 1.7rem; } }
  .project__type {
    text-transform: uppercase;
    color: #b3b2b0;
    font-size: 0.9rem;
    margin-top: 0.4em; }
  .project__tech {
    font-style: italic; }
  .project__demo-button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    width: 175px;
    margin-top: 3em;
    font-size: 0.8rem; }
    @media (min-width: 768px) {
      .project__demo-button {
        font-size: 1rem; } }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px 10px 10px 0;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 20px; }
    @media (min-width: 768px) {
      .Collapsible__contentInner p {
        font-size: 0.9rem; } }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
  color: grey;
  position: relative;
  border-bottom: 1px solid lightgrey;
  padding: 15px 10px 15px 0;
  color: black; }
  @media (min-width: 768px) {
    .Collapsible__trigger {
      font-size: 1rem; } }
  .Collapsible__trigger:after {
    font-family: "FontAwesome";
    content: "❮";
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    color: lightgrey; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.project--df {
  border-top: 20px solid #d25355; }
  @media (min-width: 768px) {
    .project--df {
      border-top: none;
      border-left: 20px solid #d25355; } }
  .project--df__demo-button {
    background: #d25355; }

@charset "UTF-8";
/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif; }

/* --- Import Fonts ---*/
@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap"); }

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap"); }

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap"); }

/* --- Global Variables ---*/
/* --- Global Mixins ---*/
/* --- Global Colors ---*/
.project {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3em 0 3em 0; }
  @media (min-width: 768px) {
    .project {
      border-top: none; } }
  .project__container {
    box-sizing: content-box;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .project__image-container {
    display: flex;
    justify-content: center; }
  .project__image--desk {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--desk {
        max-width: 650px; } }
  .project__image--tablet {
    width: 80%;
    -webkit-filter: drop-shadow(11px 8px 8px lightgrey);
            filter: drop-shadow(11px 8px 8px lightgrey); }
    @media (min-width: 768px) {
      .project__image--tablet {
        max-width: 650px; } }
  .project__text-container {
    width: 100%;
    margin-top: 5em; }
  .project__text {
    margin: 1em 0; }
  .project__title {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .project__title {
        font-size: 1.7rem; } }
  .project__type {
    text-transform: uppercase;
    color: #b3b2b0;
    font-size: 0.9rem;
    margin-top: 0.4em; }
  .project__tech {
    font-style: italic; }
  .project__demo-button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    width: 175px;
    margin-top: 3em;
    font-size: 0.8rem; }
    @media (min-width: 768px) {
      .project__demo-button {
        font-size: 1rem; } }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px 10px 10px 0;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 20px; }
    @media (min-width: 768px) {
      .Collapsible__contentInner p {
        font-size: 0.9rem; } }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
  color: grey;
  position: relative;
  border-bottom: 1px solid lightgrey;
  padding: 15px 10px 15px 0;
  color: black; }
  @media (min-width: 768px) {
    .Collapsible__trigger {
      font-size: 1rem; } }
  .Collapsible__trigger:after {
    font-family: "FontAwesome";
    content: "❮";
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    color: lightgrey; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.project--ff {
  border-top: 20px solid #26558b; }
  @media (min-width: 768px) {
    .project--ff {
      border-top: none;
      border-left: 20px solid #26558b; } }
  .project--ff__demo-button {
    background: #26558b;
    color: white; }

