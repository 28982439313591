// global stlyes  for project components

.project {
  width: 100%;
  height: 100vh;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  padding: 3em 0 3em 0;

  @include tablet-view {
    border-top: none;
  }
  &__container {
    box-sizing: content-box;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image--desk {
    width: 80%;
    filter: drop-shadow(11px 8px 8px lightgrey);

    @include tablet-view {
      max-width: 650px;
    }
  }

  &__image--tablet {
    width: 80%;
    filter: drop-shadow(11px 8px 8px lightgrey);

    @include tablet-view {
      max-width: 650px;
    }
  }
  &__text-container {
    width: 100%;
    margin-top: 5em;
  }

  &__text {
    margin: 1em 0;
  }

  &__title {
    margin-bottom: 0;

    @include tablet-view {
      font-size: 1.7rem;
    }
  }

  &__type {
    text-transform: uppercase;
    color: #b3b2b0;
    font-size: 0.9rem;
    margin-top: 0.4em;
  }

  &__info {
  }

  &__tech {
    font-style: italic;
  }

  &__demo-button {
    @include button-styles;
    //background: lightgrey;
  }
}

//the main container element
.Collapsible {
  background-color: white;
}

//the content within the collapsible area
.Collapsible__contentInner {
  padding: 10px 10px 10px 0;
  border-top: 0;

  // the hidden text
  p {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 20px;

    @include tablet-view {
      font-size: 0.9rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//the component that opens/closes the collapsible area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  font-size: 0.8rem;
  text-decoration: none;
  color: grey;
  position: relative;
  border-bottom: 1px solid lightgrey;

  padding: 15px 10px 15px 0;
  color: black;

  @include tablet-view {
    font-size: 1rem;
  }

  &:after {
    font-family: "FontAwesome";
    content: "❮";
    transform: rotateZ(-90deg);
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
    color: lightgrey;
  }

  &.is-open {
    &:after {
      transform: rotateZ(90deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}
