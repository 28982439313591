@import "./styles/base.scss";

.App {
  display: flex;
  flex-wrap: wrap;

  @include tablet-view {
    flex-wrap: nowrap;
  }
}

.project-container {
  //width: 100vw;

  @include tablet-view {
    width: 60vw;
    position: static;
    overflow: visible;
    width: 60%;
    //max-width: 1200px;
    //margin-left: 40%;
    float: none;
  }
}

/* 
* Changing navigation bullets colors 
*/
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: lightgrey;
}
