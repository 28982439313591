// Global Styles

/* --- Style Reset ---*/
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

/* --- Import Fonts ---*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");

@font-face {
  font-family: lato-regular;
  src: url("https://fonts.googleapis.com/css?family=Lato&display=swap");
}

@font-face {
  font-family: lato-bold-700;
  src: url("https://fonts.googleapis.com/css?family=Lato:700&display=swap");
}

@font-face {
  font-family: lato-bold-900;
  src: url("https://fonts.googleapis.com/css?family=Lato:900&display=swap");
}

/* --- Global Variables ---*/

$tablet-min-size: 768px;
$desk-min-size: 1440px;

/* --- Global Mixins ---*/

@mixin tablet-view {
  @media (min-width: $tablet-min-size) {
    @content;
  }
}

@mixin desk-view {
  @media (min-width: $desk-min-size) {
    @content;
  }
}

@mixin button-styles {
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
  width: 175px;
  margin-top: 3em;
  font-size: 0.8rem;

  @include tablet-view {
    font-size: 1rem;
  }
}

/* --- Global Colors ---*/
$brand: pink;
