// Info scss

@import "../../styles/base.scss";

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");

.info-section {
  width: 100vw;
  height: 100vh;

  @include tablet-view {
    width: 40%;
  }
  &__container {
    width: 100%;
    height: 100vh;
    min-height: 400px;

    display: flex;
    justify-content: center;
    //background-color: #efefef;

    @include tablet-view {
      position: fixed;

      width: 40vw;

      //left: -120px;
      //top: 0px;
      //right: 552px;
      //bottom: 0px;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
  }

  &__title {
    font-size: 2.5rem;
    width: 200px;
  }

  &__text {
    margin: 2em 0;
    line-height: 1.5rem;
    max-width: 350px;
  }

  &__icon-wrapper {
    width: 175px;
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 2em;
  }

  &__button {
    @include button-styles;
    background: lightpink;
    margin-top: 0;
  }
}

.bold {
  font-weight: bold;
}
